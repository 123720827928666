<template>
    <div class="MonitorDetailBaseBox">
        <loading v-if="isLoading" size="30px" style="height: 100%;"></loading>
        <detail-title class="header" :titleData="titleData" v-if="!isLoading" :routerParams="routerParams" @processAll="processAll" :detailList="detailList"></detail-title>
        <div class="container">
            <scroll v-if="!isLoading&&detailList.length>0" class="wrapper" ref="scroll" :pullDownRefresh="pullDownRefreshObj" :pullUpLoad="pullUpLoadObj" :startY="parseInt(startY)" @pullingDown="onPullingDown" @pullingUp="onPullingUp">
                <ul ref="wrapper" style="padding: 0 15px;">
                    <li class="docItem" v-for="(item,index) in detailList" :key="index">
                        <div class="itemTitle">
                            <div class="left red">{{returnReason(item.reason)}}</div>
                            <div class="right">{{item.finishTime}}</div>
                        </div>
                        <div class="itemTitle">
                            <div class="left">{{item.hint}}</div>
                        </div>
                        <div class="itemTitle" style="text-align: right;">
                            <van-button round size="small" plain @click="toLogUrl(item.lastRawLog)" v-show="item.hasOwnProperty('lastRawLog') && item.logFile != ''">查看日志</van-button>
                            <van-button round size="small" plain v-show="item.status != 1 " @click="setMarkSolved('markSolved', item.taskId)">置为已处理</van-button>
                        </div>
                    </li>
                </ul>
            </scroll>
        </div>
        <div class="footer" @click="showSelect" v-if="detailList.length > 0">人工干预</div>
        <!-- 弹出选项 -->
        <van-action-sheet v-model="show" :actions="actions" @select="onSelect" />
    </div>
</template>

<script>
import detailTitle from './detailTitle'
import BScroll from 'better-scroll'
import Scroll from '@/components/Scroll/index'
import titleMixin from '@/mixin/title'
export default {
    name: 'MonitorDetail',
    title: '邮件监视',
    mixins: [titleMixin],
    data() {
        return {
            pullDownRefresh: true,
            pullDownRefreshThreshold: 60,
            pullDownRefreshStop: 60,
            pullUpLoad: true,
            pullUpLoadThreshold: 0,
            pullUpLoadMoreTxt: '加载更多',
            pullUpLoadNoMoreTxt: '没有更多数据了',
            show: false,
            actions: [
                { name: '重启收件', code: 'restart' },
                { name: '切换为初始化', code: 'init' },
                { name: '切换邮件服务器', code: 'switchChannel' }
            ],
            routerParams: {},
            page: {
                pageN: 1,
                pageSize: 10,
                total: 0
            },
            isLoading: false,
            isTitleLoading: false,
            detailList: [],
            titleData: {},
            scroll: null,
            startY: 0
        }
    },
    created() {
    },
    computed: {
        pullDownRefreshObj: function () {
            return this.pullDownRefresh ? {
                threshold: parseInt(this.pullDownRefreshThreshold),
                stop: parseInt(this.pullDownRefreshStop)
            } : false
        },
        pullUpLoadObj: function () {
            return this.pullUpLoad ? {
                threshold: parseInt(this.pullUpLoadThreshold),
                txt: { more: this.pullUpLoadMoreTxt, noMore: this.pullUpLoadNoMoreTxt }
            } : false
        }

    },
    mounted() {
        this.setMenu()
        this.reGetList()
    },
    methods: {
        processAll() {
            this.process()
        },
        async process() {
            let taskId
            if (this.detailList.length > 0) {
                taskId = this.detailList[0].taskId
                await this.getIntervene('markSolved', taskId, 'all')
                this.detailList = await this.getDetailList()
            }
        },
        toLogUrl(url) {
            window.open(url)
        },
        async setMarkSolved(str, taskId) {
            await this.getIntervene(str, taskId)
            this.detailList = await this.getDetailList()
        },
        returnReason(str) {
            if (str == 900) {
                return '认证失败'
            } else if (str == 700) {
                return '邮局踢出'
            } else if (str == 600) {
                return '连接超时'
            } else if (str == 800) {
                return '异常邮件'
            } else if (str == 0) {
                return '正常'
            }
        },
        async getTaskStatus() {
            this.routerParams = this.$route.query
            if (JSON.stringify(this.routerParams) != '{}' && this.routerParams.corpId != undefined) {
                let params = {
                    corpId: this.routerParams.corpId,
                    mailAccount: this.routerParams.mailAccount
                }
                this.isTitleLoading = true
                await this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.mailWatcher_taskStatus, { params: params }).then(res => {
                    if (res.data.code.toString() == this.Global.config.RES_OK) {
                        this.isTitleLoading = false
                        this.titleData = res.data.data
                        if (this.titleData.hasOwnProperty('initFlag') || this.titleData.initFlag == 1) {
                            this.actions[1].name = '切换为未初始化'
                        } else {
                            this.actions[1].name = '切换为已初始化'
                        }
                    } else {
                        this.isTitleLoading = false
                        this.$toast.fail(res.data.msg)
                    }
                }).catch(error => {
                    this.isTitleLoading = false
                    console.log(error)
                })
            }
        },
        async onSelect(item) {
            // 点击选项时默认不会关闭菜单，可以手动关闭
            this.show = false
            let taskId
            if (this.detailList.length > 0) {
                taskId = this.detailList[0].taskId
                await this.getIntervene(item.code, taskId)
                this.detailList = await this.getDetailList()
            }
        },
        async getIntervene(optCode, taskId, type) {
            this.isLoading = true
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.v2.mailWatcher_abNormalList
                let params = {
                    optCode: optCode,
                    taskId: taskId,
                    referer: 'taskDetail'
                }
                if (type == 'all') {
                    params.type = 'all'
                }
                let res = await this.axios.put(url, params)
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.isLoading = false
                    this.$toast.success(res.data.msg)
                } else {
                    this.isLoading = false
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {
                this.isLoading = false
                console.log(error)
            }
        },
        showSelect() {
            this.show = true
        },
        setScroll() {
            this.$nextTick(() => {
                if (!this.scroll) {
                    this.scroll = new BScroll(this.$refs.wrapper, {
                        click: true,
                        probeType: 3
                    })
                } else {
                    this.scroll.refresh()
                }
            })
        },
        setMenu() {
            this.Global.utils.rightMenu.clearMenu()
        },
        async onPullingDown() {
            this.page.pageN = 1
            this.detailList = await this.getDetailList()
            if (this._isDestroyed) {
                return
            }
            this.$nextTick(() => {
                this.$refs.scroll.forceUpdate()
            })
        },
        rebuildScroll() {
            this.$nextTick(() => {
                this.$refs.scroll.destroy()
                this.$refs.scroll.initScroll()
            })
        },
        async onPullingUp() {
            if (this.detailList.length >= this.page.total) {
                this.$refs.scroll.forceUpdate(false)
                return
            }
            if (this.detailList.length < this.page.total) {
                this.page.pageN += 1
                let list = await this.getDetailList()
                this.detailList = this.detailList.concat(list)
                this.$nextTick(() => {
                    if (this.detailList.length >= this.page.total) {
                        this.$refs.scroll.forceUpdate(false)
                    } else {
                        this.$refs.scroll.forceUpdate(true)
                    }
                })
            }
        },
        async getDetailList() {
            this.routerParams = this.$route.query
            let listArr = []
            if (JSON.stringify(this.routerParams) != '{}' && this.routerParams.corpId != '') {
                try {
                    let url = this.Global.config.apiBaseURL + this.Global.api.v2.mailWatcher_abNormalList
                    let params = {
                        corpId: this.routerParams.corpId,
                        mailAccount: this.routerParams.mailAccount,
                        pageN: this.page.pageN,
                        pageSize: this.page.pageSize,
                        days: this.routerParams.days
                    }
                    let res = await this.axios.get(url, { params })
                    if (res.data.code.toString() == this.Global.config.RES_OK && this.Global.utils.isObject(res.data.data)) {
                        listArr = res.data.data.dataList
                        this.page.total = res.data.data.totalNum
                    } else {
                        this.$toast.fail(res.data.msg)
                    }
                } catch (error) {
                    console.log(error)
                }
            }
            return listArr
        },
        async reGetList() {
            this.page.pageN = 1
            this.isLoading = true
            this.detailList = await this.getDetailList()
            await this.getTaskStatus()
            this.isLoading = false
        }
    },
    watch: {
        $route() {
            if (this.$route.path == '/mailmonitor/monitordetail') {
                this.reGetList()
            }
        },
        pullDownRefreshObj: {
            handler(val) {
                const scroll = this.$refs.scroll.scroll
                if (val) {
                    scroll.openPullDown()
                } else {
                    scroll.closePullDown()
                }
            },
            deep: true
        },
        pullUpLoadObj: {
            handler(val) {
                const scroll = this.$refs.scroll.scroll
                if (val) {
                    scroll.openPullUp()
                } else {
                    scroll.closePullUp()
                }
            },
            deep: true
        },
        startY() {
            this.rebuildScroll()
        }
    },
    components: {
        'detail-title': detailTitle,
        'scroll': Scroll
    }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
