<template>
    <div class="detailTitle">
        <div class="detailHeader">
            <div class="left">
                <div class="bold">{{titleData.mailAddress}} <span style="margin-left: 5px;font-size:12px;font-weight: normal">{{titleData.initFlag == '1'?'初始化完成':'初始化未完成'}}</span></div>
                <div>{{titleData.companyName}} <span style="margin-left: 5px;">{{titleData.serverType == '0'?'pop':'imap'}}</span></div>
            </div>
            <van-button round size="small" plain @click.stop="toAllTask" class="titleBtn">所有任务</van-button>
        </div>
        <van-row class="emailNum">
            <van-col span="5">
                <div>邮件数</div>
                <div class="bold">{{titleData.totalCount}}</div>
            </van-col>
            <van-col span="5">
                <div>新邮件</div>
                <div class="bold">{{titleData.newMessageCount}}</div>
            </van-col>
            <van-col span="5">
                <div>已接收</div>
                <div class="bold">{{titleData.currentNum?titleData.currentNum:0}}</div>
            </van-col>
            <van-col span="9">
                <div>正常收件</div>
                <div class="text">{{titleData.hint}}</div>
            </van-col>
        </van-row>
        <div style="font-size: 12px;position: relative;height: 30px;line-height: 30px;">
            收件服务：
            <span>{{titleData.serverAlias?titleData.serverAlias:titleData.ip}}</span>
            <span style="margin-left: 5px">{{titleData.prop== '0'?'公共邮箱':titleData.prop== '1'?'个人邮箱': ''}}</span>
            <!-- <span style="margin-left: 5px">{{titleData.initFlag == '0'?'初始化未完成':'初始化完成'}}</span> -->
            <span style="position: absolute;right: 0;top: 0;">
                <van-button plain type="primary" size="small" round @click="$emit('processAll')" v-show="detailList.length>0">全置为已处理</van-button>
                <van-button plain type="primary" size="small" round @click="toLogPage">操作日志</van-button>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'detailTitle',
    data() {
        return {
            loading: true
        }
    },
    props: {
        titleData: {
            type: Object,
            default: function () {
                return {}
            }
        },
        routerParams: {
            type: Object,
            default: function () {
                return {}
            }
        },
        isTitleLoading: {
            type: Boolean,
            default: true
        },
        detailList: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    computed: {
        received() {
            // if (this.titleData.hasOwnProperty('currentOrder')) {
            //     let result = this.titleData.currentOrder
            //     if () {
            //     }
            // } else {
            //     return 0
            // }
        }
    },
    created() {
    },
    mounted() { },
    methods: {
        toLogPage() {
            this.$router.push(
                {
                    path: '/mailmonitor/tasklog',
                    query: {
                        corpId: this.routerParams.corpId, mailAccount: this.routerParams.mailAccount, days: this.routerParams.days
                    }
                }
            )
        },
        toAllTask() {
            this.$router.push(
                {
                    path: '/mailmonitor/alltask',
                    query: {
                        corpId: this.routerParams.corpId, mailAccount: this.routerParams.mailAccount, days: this.routerParams.days, id: this.routerParams.id
                    }
                }
            )
        }
    },
    watch: {
        isTitleLoading() {
            this.loading = this.isTitleLoading
        }
    }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
.detailTitle {
    background: #fff;
    padding: 10px;
}
.detailHeader {
    position: relative;
    .left {
        width: 70%;
        font-size: 12px;
        .bold {
            font-weight: bold;
            font-size: 15px;
        }
    }
}
.titleBtn {
    position: absolute;
    right: 0px;
    top: 0;
}
.emailNum {
    margin-top: 10px;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
    .bold {
        font-weight: bold;
    }
    .text {
        height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
</style>
